export default {
    C001: '<p class="text-center">入力されているメールアドレスにユーザIDとパスワードを通知しますか？</p><p class="text-center">（メール送信とともにデータも保存されます）</p>',
    C002: '入力データを保存しますか？',
    C003: '<p class="text-danger">登録データを削除しますか？</p>',
    C004: '<p class="text-center">請求締切取消処理を開始します。</p><p class="text-center">この処理は、中断できません。</p><p class="text-center">よろしいですか？</p>',
    C005: 'ファイル出力が終了しました。',
    C006: '勤怠集計取消処理を開始します。<br>この処理は、中断できません。<br>よろしいですか？',
    C007: '処理を中止しました。',
    C008: '<p class="text-center">請求締処理を開始します。この処理は、中断できません。</p><p class="text-center">よろしいですか？</p>',
    C009: '一覧表を印刷しますか？',
    C010: '選択された勤務表を印刷しますか？',
    C011: '集計処理を開始します。この処理は、中断できません。<br>よろしいですか？',
    C012: '処理を中止しました。',
    C013: '選択しているデータを隊員マスタへ登録します。<br/>既存データは上書き、それ以外は新規に登録されます。<br/>この処理は、途中でキャンセルすることができません。<br/>よろしいですか？',
    C014: '請求書を印刷しますか？',
    C015: '<p class="text-center">一括登録します。この処理は、中断できません。</p><p class="text-center">よろしいですか？</p>',
    C016: '一括登録が終了しました。',
    C017: '出勤簿を印刷しますか？',
    C018: '<p class="text-danger">入力データを１行削除しますか？</p>',
    C019: 'データを正常に保存',
    C020: '<p class="text-danger">隊員を削除しますか？</p>',
    // Error message
    E001: 'を入力して下さい',
    E002: 'メールアドレスの形式が正しくありません',
    E003: 'メールアドレスを入力してください。',
    E004: 'IDまたはパスワードが有効ではありません',
    E005: '請求取消を行う得意先を選択して下さい。',
    E006: '得意先Noを指定して下さい。',
    E007: '売上金額が0円の明細があります。売上金額を入力して下さい。',
    E008: '出力する隊員データを選択して下さい',
    E009: '売上CDを指定して下さい。',
    E010: '勤怠集計取消を行う隊員を選択して下さい。',
    E011: '請求締切を行う得意先を選択して下さい。',
    E012: '最低１件は選択して下さい。',
    E013: '追加する日付を入力して下さい。',
    E014: '勤怠集計処理を行う隊員を選択して下さい。',
    E015: '給与ソフトから出力した隊員ファイルを選択して下さい。',
    E016: '隊員マスタへインポートする隊員を選択して下さい。',
    E018: '会社名を入力して下さい',
    E019: '１件以上を選択して下さい',
    E020: '研修日を入力して下さい。',
    E021: '研修名を入力して下さい。',
    E022: '出勤簿を選択して下さい。',
    E023: '該当データは存在しません。',
    E024: '表示年月日を指定して下さい',
    E025: 'ファイル名の形式が正しくありません。',
    E026: '削除の権限がありません',
    //
    E027: '事業所が選択されていません',
    E028: '得意先が選択されていません',
    E029: '現場が選択されていません',
    //
    E041: '出力処理がタイムアウトになりました',
    // api error
    E400: 'エラーが発生しました。もう一度お願いします。',
    E401: 'ログインセッションの有効期限が切れています。もう一度ログインしてください。',
    E403: 'この機能にアクセスする権限がありません。',
    E404: 'エラーが発生しました。もう一度お願いします。',
    E422: 'エラーが発生しました。もう一度お願いします。',
    E423: 'エラーが発生しました。もう一度お願いします。',
    E500: 'エラーが発生しました。もう一度お願いします。',
    E999: 'エラーが発生しました。もう一度お願いします。'
};

export const MSG_TITLE = {
    E999: 'エラー',
    C001: 'メール送信確認',
    C002: '保存確認',
    C003: '<p class="text-danger">削除確認</p>',
    C004: '請求取消確認',
    C005: '集計ファイル',
    C006: '集計取消確認',
    C007: '集計取消中止',
    C008: '請求締切確認',
    C009: '印刷確認',
    C010: '印刷確認',
    C011: '勤怠集計確認',
    C012: '集計処理中止',
    C013: 'マスタ登録',
    C014: '一括登録確認',
    C015: '成功'
};

export const MSG_TYPE = {
    CONFIRM: 1,
    WARNING: 2,
    SUCCESS: 3,
    ERROR: 4
};
