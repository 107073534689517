export default {
    empno: '隊員№',
    empname: '隊員名',
    empkana: 'フリガナ',
    locationno: '事業所',
    enrollno: '在籍区分',
    closeno: '集計グループ',
    sikyuno: '支給区分',
    // 基本
    seibetuno: '性別',
    birthday: '生年月日',
    bloodno: '血液型',
    rhno: 'RH型',
    postalno: '郵便番号',
    address1: '住所', // 住所
    address2: 'ビル・建物', // ビル・建物
    tel: '電話番号',
    cellphone: '携帯番号',
    mail: 'メールアドレス',
    Joindate: '入社年月日',
    retiredate: '退職年月日',
    retireno: '退職区分',
    retirereason: '退職理由',
    // 主要資格
    drivelicense: '普通運転免許',
    guidelicense1: '交通誘導員1級',
    guidelicense2: '交通誘導員2級',
    // その他資格
    otherslicense1: '1', // その他資格1
    otherslicense2: '2', // その他資格2
    otherslicense3: '3', // その他資格3
    otherslicense4: '4', // その他資格4
    // 講習会
    trainingdate: '参加日', // 講習会参加日
    trainingdetail: '内容', // 講習会内容
    // 単価
    t_tan: '通常単価',
    t_zan: '通常残業',
    y_t_tan: '夜間単価',
    y_t_zan: '夜間残業',
    d_tan: '土曜単価',
    d_zan: '土曜残業',
    y_d_tan: '土曜夜間単価',
    y_d_zan: '土曜夜間残業',
    k_tan: '休日単価',
    k_zan: '休日残業',
    y_k_tan: '休日夜間単価',
    y_k_zan: '休日夜間残業',
    koutuhi: '交通費',
    // 退職後住所
    retirepostalno: '郵便番号', // 退職後郵便番号
    retireaddress1: '住所', // 退職後住所1
    retireaddress2: 'ビル・建物', // 退職後住所2
    retiretel: '電話番号', // 退職後電話番号
    retirecellphone: '携帯番号', // 退職後携帯番号
    // 退職後企業情報
    restartdate: '復職年月日',
    companyname: '企業名',
    companypostalno: '郵便番号', // 退職後企業郵便番号
    companyaddress1: '住所', // 退職後企業住所1
    companyaddress2: 'ビル・建物', // 退職後企業住所2
    companytel: '電話番号', // 退職後企業電話番号
    companycellphone: '携帯番号', //退職後企業携帯番号
    // 健康保険
    familysame: '基本と同じ',
    familypostalno: '郵便番号',
    familyaddress1: '住所',
    familyaddress2: 'ビル・建物',
    familytel: '電話番号',
    familycellphone: '携帯番号',
    // 健康保険
    kenkohokenname: '保険名称',
    kenkohokenno: '保険番号',
    // 年金保険
    benkinhokenname: '保険名称',
    benkinhokenno: '保険番号',
    // 雇用保険
    koyouhokenname: '保険名称',
    koyouhokenno: '保険番号',
    // 健康診断
    kenkocheckdate: '診断日',
    kenkobloodfrom: '血圧',
    kenkobloodto: '血圧',
    // 特殊健康診断
    specialcheckdate: '診断日',
    specialbloodfrom: '血圧',
    specialbloodto: '血圧',
    // 特別教育
    specialmenzyo: '免除',
    specialdate: '実施日',
    // 技能教育
    skillmenzyo: '免除',
    skilldate: '実施日',
    // メモ
    memo: 'メモ'
};
