export default {
    customerno: '得意先№',
    customername: '得意先名称',
    tantouno: '担当者No',
    tantouname: '担当者名',
    tel: '電話番号',
    naisentel: '内線番号',
    fax: 'FAX番号',
    mail: 'メールアドレス',
    kubunno: '担当区分',
    memo: 'メモ'
};
