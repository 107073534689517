export default {
    officecd: '事業所No',
    officename: '事業所名',
    abbreviation: '略称',
    basic: '基本',
    postcode: '郵便番号',
    address1: '住所',
    address2: 'ビル・建物',
    tel: '電話番号',
    fax: 'FAX番号',
    representative: '代表者名',
    memo: 'メモ'
};
