import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dayjs from 'dayjs';
import vClickOutside from 'click-outside-vue3';
import BootstrapVue3 from 'bootstrap-vue-3';
import VueCookies from 'vue3-cookies';

import BsInput from '@/components/bsInput';
import BsDatePicker from '@/components/bsDatePicker';
import BsSelect from '@/components/bsSelect';
import BsCheckbox from '@/components/bsCheckbox';
import BsRadioButton from '@/components/bsRadioButton';
import BsFile from '@/components/bsFile';
import BsSwitch from '@/components/bsSwitch';
import BsButton from '@/components/bsButton';
import BsInputSearch from '@/components/bsInputSearch';
import BsList from '@/components/bsList';
import BsTable from '@/components/bsTable';
import BsPanelHeader from '@/components/bsPanelHeader';
import BsPanel from '@/components/bsPanel';
import BsPostCode from '@/components/bsPostCode';
import VueSignaturePad from 'vue-signature-pad';

const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
app.config.productionTip = false;
app.use(vClickOutside);
app.use(BootstrapVue3);
app.use(VueCookies, {
    expireTimes: '30d',
    path: '/'
});
app.use(store);
app.use(router);
app.use(VueSignaturePad);

app.component('BsInput', BsInput);
app.component('BsDatePicker', BsDatePicker);
app.component('BsSelect', BsSelect);
app.component('BsCheckbox', BsCheckbox);
app.component('BsRadioButton', BsRadioButton);
app.component('BsFile', BsFile);
app.component('BsSwitch', BsSwitch);
app.component('BsButton', BsButton);
app.component('BsInputSearch', BsInputSearch);
app.component('BsList', BsList);
app.component('BsTable', BsTable);
app.component('BsPanelHeader', BsPanelHeader);
app.component('BsPanel', BsPanel);
app.component('BsPostCode', BsPostCode);

app.mount('#app');
