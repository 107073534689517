export default {
    payno: '入金管理№',
    payday: '入金日',
    customerno: '得意先No',
    customerid: '得意先ID',
    customername: '得意先名称',
    customerrk: '得意先略称',
    closedate: '請求締日',
    y_paymonth: '入金予定月',
    y_payday: '入金予定日',
    lastseikyuzan: '前回請求残高',
    paytotal: '今回入金額',
    seikyugaku: 'ご請求額',
    seikyuzan: '請求残高',
    d_payno: '入金No',
    d_payname: '入金名称',
    d_paymoney: '入金金額',
    d_sonota: 'その他'
};
