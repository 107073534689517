import messages from './messages';
import label from './label';
import store from '@/store';
import moment from 'moment';
import _ from 'lodash';
import Router from '@/router';

export default {
    removeJapaneseCharacter: (str) => {
        if (str == undefined || str == null) {
            return '';
        }
        const regex =
            /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
        return str.replace(regex, '');
    },
    insertComma: (val) => {
        return (val + '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    isValidData: (data, rules) => {
        try {
            const isEmail = (str) => {
                if (str == null || str == undefined || str == '') {
                    return true;
                }
                return String(str)
                    .toLowerCase()
                    .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            };
            let isValid = true;
            const screenId = store.state.app.screenId;
            for (const key in rules) {
                if (Object.prototype.hasOwnProperty.call(rules, key)) {
                    let tbl = label[screenId][key];
                    const value = rules[key];
                    const values = value.split('|');
                    const length = values.length;
                    if (tbl == undefined || tbl == null || tbl == '') {
                        tbl = '項目';
                    }
                    for (let i = 0; i < length; i++) {
                        const rule = values[i];
                        const checks = rule.split(':');
                        if (checks[0] == 'required') {
                            if (
                                !Object.prototype.hasOwnProperty.call(
                                    data,
                                    key
                                ) ||
                                data[key] == null ||
                                data[key] == '' ||
                                (data[key] == '0' && key == 'locationcd') ||
                                (data[key] == '0' && key == 'locationno')
                            ) {
                                if (isValid) {
                                    store.commit('app/showHeaderError', [
                                        tbl + messages.E001
                                    ]);
                                }
                                isValid = false;
                            }
                        }
                        if (checks[0] == 'email') {
                            if (!isEmail(data[key])) {
                                if (isValid) {
                                    store.commit('app/showHeaderError', [
                                        messages.E035
                                    ]);
                                }
                                isValid = false;
                            }
                        }
                    }
                }
            }
            return isValid;
        } catch (e) {
            console.log('validData: ' + e.message);
            return false;
        }
    },

    /**
     *
     * @param {*} type type = 1 (type: 'characters')
     * @param {*} type type = 2 (type: 'string')
     * @param {*} type type = 3 (type: 'number')
     * @param {*} length
     * @returns
     */
    randomString: (type, length) => {
        try {
            let result = '';
            let lengthKey = 0;
            let Key = '';
            const characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const texts =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            const numbers = '0123456789';
            if (type == 1) {
                lengthKey = characters.length;
                Key = characters;
            } else if (type == 2) {
                lengthKey = texts.length;
                Key = texts;
            } else {
                lengthKey = numbers.length;
                Key = numbers;
            }
            for (var i = 0; i < length; i++) {
                result += Key.charAt(Math.floor(Math.random() * lengthKey));
            }
            return result;
        } catch (e) {
            console.log('randomString: ' + e.message);
            return false;
        }
    },
    splitByType: (str) => {
        str = str + '';
        const length = str.length;
        let beforeChar = '';
        let childStr = '';
        const result = [];
        for (let i = 0; i < length; i++) {
            const currentChar = str[i];
            if (
                (isNaN(beforeChar) && !isNaN(currentChar)) ||
                (!isNaN(beforeChar) && isNaN(currentChar))
            ) {
                if (childStr != '') {
                    const child = '00000000000000000000' + childStr;
                    result.push(
                        !isNaN(childStr)
                            ? child.substring(child.length - 20, child.length)
                            : childStr
                    );
                    childStr = currentChar;
                } else {
                    childStr = currentChar;
                }
            } else {
                childStr += currentChar;
            }
            beforeChar = currentChar;
        }
        if (childStr != '') {
            const child = '00000000000000000000' + childStr;
            result.push(
                !isNaN(childStr)
                    ? child.substring(child.length - 20, child.length)
                    : childStr
            );
        }
        return result;
    },
    getNumber: (str, emptyIsMax) => {
        if (str == null || str == undefined || str == '') {
            if (emptyIsMax) {
                return Number.MAX_SAFE_INTEGER;
            }
            return 0;
        }
        str = (str + '').replace(/\D/g, '');
        if (str == '') {
            if (emptyIsMax) {
                return Number.MAX_SAFE_INTEGER;
            }
            return 0;
        }
        return parseInt(str);
    },
    isEmail: (str) => {
        if (str == null || str == undefined || str == '') {
            return true;
        }
        return String(str)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    },
    isNullOrEmpty: (str) => {
        if (str == undefined || str == null || str == '') {
            return true;
        }
        return false;
    },
    encodeParams: (params) => {
        try {
            if (params == undefined || params == null || params.length == 0) {
                return '';
            }
            let output = btoa(JSON.stringify(params));
            let endPos = 0;
            for (endPos = output.length; endPos > 0; endPos--) {
                if (output[endPos - 1] !== '=') {
                    break;
                }
            }
            const numberPaddingChars = output.length - endPos;
            output = output.replace(/\+/g, '-');
            output = output.replace(/\//g, '_');
            output = output.substring(0, endPos);
            output = output + numberPaddingChars;

            return output;
        } catch (e) {
            return '';
        }
    },
    decodeParams: (params) => {
        try {
            if (params == undefined || params == null || params.length == '') {
                return {};
            }
            var numberPading = parseInt(params.substring(params.length - 1));
            params = params.substring(0, params.length - 1);
            params = params.replace(/-/g, '+');
            params = params.replace(/_/g, '/');
            for (var j = 0; j < numberPading; j++) {
                params = params + '=';
            }
            params = params.replace(/[^A-Za-z0-9+/=]/g, '');
            params = atob(params);
            return JSON.parse(params);
        } catch (e) {
            return {};
        }
    },
    getCloseDate(closeno) {
        let date = '';
        if (closeno != 0 && closeno != 7) {
            let today = new Date();
            let currentDay = today.getDate();
            if (closeno > 0 && closeno < 6) {
                today.setDate(parseInt(closeno) * 5);
            } else if (closeno == 71) {
                // 給与土曜締 - Saturday closing
                let day = moment(today).day();
                if (day == 0) {
                    // Sun
                    today = moment(today).set('date', currentDay - 1);
                } else if (day < 6) {
                    // Mon -Fri
                    today = moment(today).set('date', currentDay - day - 1);
                    date = moment(today).format('YYYY/MM/DD');
                    return date;
                }
            } else if (closeno == 31) {
                const lastDay = moment(today).endOf('month').format('D');
                if (moment(today).date() > currentDay || currentDay < lastDay) {
                    today = moment(today).set(
                        'month',
                        moment(today).month() - 1
                    );
                }
                today = moment(today).endOf('month');
                date = moment(today).format('YYYY/MM/DD');
                return date;
            } else {
                today = moment(today).endOf('month');
            }
            // check day > current day => return previous month
            if (moment(today).date() > currentDay) {
                today = moment(today).set('month', moment(today).month() - 1);
            }
            date = moment(today).format('YYYY/MM/DD');
        }
        if (closeno == 99) {
            return (date = '');
        }
        return date;
    },
    getCloseno(listClose, id) {
        let code = 0;
        if (listClose.length > 0) {
            if (listClose[1].code > 100) {
                code = 100;
            }
        }
        if (id == 6) {
            // get last day of the month
            code += 1;
        }
        return code;
    },
    convertCloseno(closeno, code) {
        let result = 0;
        if (closeno > 0) {
            result = closeno - code;
            if ((closeno - code) % 5 == 0) {
                result = (closeno - code) / 5;
            }
        }
        return result;
    },
    addPadLeft(value, char, length) {
        return String(char.repeat(length) + value).slice(-1 * length);
    },
    func_back: (isDelete = false) => {
        let historyURL = JSON.parse(sessionStorage.getItem('historyURL'));
        if (!historyURL) {
            historyURL = [];
        }
        let lastObj = _.last(historyURL);
        if (isDelete) {
            const length = historyURL.length;
            if (length >= 2) {
                if (lastObj.path.split('').pop() == 'l') {
                    historyURL = _.dropRight(historyURL);
                } else {
                    historyURL.forEach((element) => {
                        if (element.path == lastObj.path) {
                            historyURL = _.dropRight(historyURL);
                        }
                    });
                    lastObj = _.last(historyURL);
                    if (lastObj.path.split('').pop() == 'l')
                        historyURL = _.dropRight(historyURL);
                }
            }
        } else {
            historyURL = _.dropRight(historyURL);
        }
        sessionStorage.setItem('historyURL', JSON.stringify(historyURL));
        store.commit('app/setBacking', true);
        if (lastObj.p.p) {
            Router.push({
                name: lastObj.name,
                query: {
                    p: lastObj.p.p
                }
            });
        } else {
            Router.push({
                name: lastObj.name
            });
        }
    },

    // get formdata
    getFormData(obj = {}, formData = new FormData(), key = '') {
        if (![Array, File, Object].includes(obj.constructor)) {
            return formData;
        }

        // Handle File recursive
        if (obj instanceof File) {
            formData.append(key, obj);
            return formData;
        }

        for (const prop in obj) {
            // Validate value type
            if (
                obj[prop] &&
                ![String, Number, Boolean, Array, Object, File].includes(
                    obj[prop].constructor
                )
            ) {
                continue;
            }

            // Set deep index of prop
            const deepKey = key ? key + `[${prop}]` : prop;

            // Handle array
            if (Array.isArray(obj[prop])) {
                obj[prop].forEach((item, index) => {
                    this.getFormData(item, formData, `${deepKey}[${index}]`);
                });
                continue;
            }

            let valid = [undefined, null].includes(obj[prop]);

            typeof obj[prop] === 'object' &&
            obj[prop] &&
            obj[prop].constructor === Object
                ? this.getFormData(obj[prop], formData, deepKey) // Handle object
                : formData.append(deepKey, valid ? '' : obj[prop]); // Handle string, number, boolean
        }

        return formData;
    },
    convertDecimal(value, decimal = 1) {
        if (value == undefined || value == '' || value == null) {
            return '';
        }
        value = value + '';
        let values = [];
        let negative = '';
        let dot = '';
        let afterDot = '';
        values = value.split('.');
        values[0] = values[0].replace(/\D/g, '');
        if (values.length > 1) {
            afterDot = values[1].replace(/\D/g, '');
            if (afterDot.length > decimal) {
                afterDot = afterDot.substring(0, decimal);
            }
            dot = '.';
        }
        if (!values[0]) {
            values[0] = 0;
        }
        return (value = negative + values[0] + dot + afterDot);
    }
};
