export default {
    uriageno: '売上No',
    uriagebi: '売上日',
    tokuisakino: '得意先No',
    tokuisakiid: '得意先ID',
    tokuisakinm: '得意先略称',
    gennbano: '現場No',
    gennbanm: '現場略称',
    zeinukiuriage: '税抜売上',
    zeikomiuriage: '税込売上',
    yoteiseikyuugaku: '予定請求額',
    // zeikomisyouhizei: '税込消費税'
    zeikomisyouhizei: '税抜消費税'
};
