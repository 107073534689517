export default {
    locationno: '事業所',
    customerno: '得意先№',
    customername: '得意先名称',
    tantouno: '担当者№',
    tantouname: '担当者名',
    genbasite: '現場種類',
    genbano: '現場№',
    genbaname: '現場名称',
    genbark: '現場略称',
    // 基本
    postalno: '郵便番号',
    address1: '住所',
    address2: 'ビル・建物',
    tel: '電話番号',
    fax: 'FAX番号',
    // A単価
    a_t_tan: '通常単価',
    a_t_zan: '通常残業',
    ay_t_tan: '夜間単価',
    ay_t_zan: '夜間残業',
    a_d_tan: '土曜単価',
    a_d_zan: '土曜残業',
    ay_d_tan: '土曜夜間単価',
    ay_d_zan: '土曜夜間残業',
    a_k_tan: '休日単価',
    a_k_zan: '休日残業',
    ay_k_tan: '休日夜間単価',
    ay_k_zan: '休日夜間残業',
    // B単価
    b_t_tan: '通常単価',
    b_t_zan: '通常残業',
    by_t_tan: '夜間単価',
    by_t_zan: '夜間残業',
    b_d_tan: '土曜単価',
    b_d_zan: '土曜残業',
    by_d_tan: '土曜夜間単価',
    by_d_zan: '土曜夜間残業',
    b_k_tan: '休日単価',
    b_k_zan: '休日残業',
    by_k_tan: '休日夜間単価',
    by_k_zan: '休日夜間残業',
    // 工期
    workno: '作業区分',
    a_nin: 'A単価',
    b_nin: 'B単価',
    schedulest: '予定工期',
    scheduleed: '予定工期終了日', // d-none
    completedate: '完了日',
    completechk: '完了',
    // line 1 end
    contimest: '工事時間',
    contimeed: '工事終了時間', // d-none
    y_contimest: '夜間時間',
    y_contimeed: '夜間工事終了時間', // d-none
    contimechk: '24時間工事',
    // line 2 end
    condatest: '工期',
    condateed: '工期終了日', // d-none
    kyucondatest: '休工',
    kyucondateed: '休工終了日', // d-none
    // 締グループ
    seikyuchk: '請求日変更',
    custclosename: '得意先締グループ',
    custpaymonth: '得意先入金予定月',
    custpayday: '日',
    closeno: '現場締グループ',
    paymonth: '現場入金予定月', // d-none
    payday: '日',
    // メモ
    memo: 'メモ'
};
