export default {
    locationno: '事業所',
    customerno: '得意先№',
    customerid: '得意先ID',
    customername: '得意先名称',
    customerrk: '得意先略称',
    customerkana: 'フリガナ',
    postalno: '郵便番号',
    address1: '住所',
    address2: 'ビル・建物',
    tel: '電話番号',
    fax: 'FAX番号',
    mail: 'メールアドレス',
    url: 'URL',
    close: '締グループ',
    closeno: '締グループ名',
    paymonth: '入金予定月',
    payday: '日',
    payment: '入金先',
    bank1: '入金先1',
    bank2: '入金先2',
    bank3: '入金先3',
    memo: 'メモ'
};
