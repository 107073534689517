import bs001000i from '@/views/bs/bs001000i/label';
import bs002000i from '@/views/bs/bs002000i/label';
import bm001000l from '@/views/bm/bm001000l/label';
import bm001000i from '@/views/bm/bm001000i/label';
import bm002000l from '@/views/bm/bm002000l/label';
import bm002000i from '@/views/bm/bm002000i/label';
import bm005000l from '@/views/bm/bm005000l/label';
import bm005000i from '@/views/bm/bm005000i/label';
import bm003000l from '@/views/bm/bm003000l/label';
import bm003000i from '@/views/bm/bm003000i/label';
import bm004000l from '@/views/bm/bm004000l/label';
import bm004000i from '@/views/bm/bm004000i/label';
import bm007000i from '@/views/bm/bm007000i/label';
import bm012000i from '@/views/bm/bm012000i/label';
import bm013000i from '@/views/bm/bm013000i/label';
import bm014000i from '@/views/bm/bm014000i/label';
import bm011000i from '@/views/bm/bm011000i/label';
import bm015000i from '@/views/bm/bm015000i/label';
import bm016000i from '@/views/bm/bm016000i/label';
import bb001000l from '@/views/bb/bb001000l/label';
import bb001000i from '@/views/bb/bb001000i/label';
import be001000i from '@/views/be/be001000i/label';
import bm009000i from '@/views/bm/bm009000i/label';
import bh001000i from '@/views/bh/bh001000i/label';
import bh002000i from '@/views/bh/bh002000i/label';
import bh002100i from '@/views/bh/bh002100i/label';
import bh002200i from '@/views/bh/bh002200i/label';
import bh001001i from '@/views/bh/bh001001i/label';
import bm010000i from '@/views/bm/bm010000i/label';
import bx001000l from '@/views/bx/bx001000l/label';

export default {
    bs001000i: bs001000i,
    bs002000i: bs002000i,
    bm001000l: bm001000l,
    bm001000i: bm001000i,
    bm002000l: bm002000l,
    bm002000i: bm002000i,
    bm005000l: bm005000l,
    bm005000i: bm005000i,
    bm003000l: bm003000l,
    bm003000i: bm003000i,
    bm004000l: bm004000l,
    bm004000i: bm004000i,
    bm007000i: bm007000i,
    bm012000i: bm012000i,
    bm011000i: bm011000i,
    bm013000i: bm013000i,
    bm014000i: bm014000i,
    bm015000i: bm015000i,
    bm016000i: bm016000i,
    bb001000l: bb001000l,
    bb001000i: bb001000i,
    be001000i: be001000i,
    bm009000i: bm009000i,
    bh001000i: bh001000i,
    bh002000i: bh002000i,
    bh002100i: bh002100i,
    bh002200i: bh002200i,
    bh001001i: bh001001i,
    bm010000i: bm010000i,
    bx001000l: bx001000l
};
