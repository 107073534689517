<template>
    <component :is="layout" />
</template>

<script>
import MainLayout from '@/shared/main-layout';
import EmptyLayout from '@/shared/empty-layout';
import { mapActions } from 'vuex';

export default {
    name: 'App',
    components: { MainLayout, EmptyLayout },
    data() {
        return {
            layout: null
        };
    },
    mounted() {
        this.getAppState();
    },
    watch: {
        $route(to) {
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout;
            } else {
                this.layout = 'MainLayout'; // this is default layout if route meta is not set
            }
        }
    },
    methods: {
        ...mapActions('app', ['getAppState'])
    }
};
</script>

<style lang="scss">
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
@import '@/assets/scss/common.scss';
</style>
