export default {
    userid: 'ユーザーID',
    username: '氏名',
    userkana: 'フリガナ',
    password: 'パスワード',
    mail: 'メールアドレス',
    location: '事業所',
    useauthority: 'システム利用権限',
    passauthority: 'パスワード保存権限',
    systemauthority: 'システム権限',
    screenauthority: 'システム機能利用権限'
};
